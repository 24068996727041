<!--
 * @Author: pengyu
 * @Date: 2021-03-04 14:23:29
 * @LastEditors: wuqi
 * @LastEditTime: 2021-09-24 10:35:22
 * @Description: 邮件--单条
-->
<template>
 <div class="mail-item" @click="handleMailClick" @contextmenu.prevent="openMenu">
   <div class='mail-item-wrap'  >
     <div class="left">
       <el-checkbox v-if="!home" v-model="mailData.checked" @click.native.stop :style="checkboxStyle"></el-checkbox>
       <en-icon name="tishi" color="#f76b6b" size="12px" v-if="mailData.urgentFlag===0"></en-icon>
       <el-avatar :alt="mailData.senderName ? mailData.senderName[0] : ''" :size="30" :class="{'noread': mailData.readFlag===1}" :style="{background: bgColor}">
         {{mailData.senderName ? mailData.senderName[0] : ''}}
       </el-avatar>
       <!-- <en-user-logo
        :user-name="mailData.senderName[0]"
        size="26"
        :class="{'noread': mailData.readFlag===1}"
      ></en-user-logo> -->
       <span   class="user-name">{{mailData.senderName || mailData.senderMail || ""}}</span>

     </div>
     <div class="middle">

       <!-- 是否含附件 -->
       <div class="tag-group">
         <!-- <en-icon name="youjianzhuizong" size="small" color="#B6C0CE" title="邮件追踪"></en-icon> -->
         <!-- v-if="mailData.attachNum > 0" -->
         <en-icon :style="{'visibility': mailData.attachNum > 0 ? 'visible' : 'hidden'}" name="fujianhetupian-kongjian" size="16" color="#B6C0CE" title="附件" ></en-icon>
       </div>
       <div class="content">
         <span class="title">{{mailData.title}}</span>
         <span class="gap"> - </span>
         <span class="brief">{{getSimpleText(mailData.content)}}</span>
       </div>
     </div>
     <div class="right">
       <div :class="['status-mod', statusClass]" v-show="mailData.folderType===6">
         {{statusName}}
       </div>
       <span></span>
       <span class="time">{{formatTime}}</span>
     </div>
   </div>
 </div>
</template>

<script>

export default {
  name: "MailMod",
  props: {
    checkboxVisible: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    mailData: {
      type: Object,
      defalt: () => {}
    },
    // 在哪里引用的 list默认列表 search搜索页面
    usedFlg: {
      type: String,
      defalt: "list"
    },
    home: {
      type: Boolean,
      default: false
    }
  },
  inject: ["$mailList"],
  computed: {
    checkboxStyle() {
      return {
        visibility: this.checkboxVisible ? "visible" : "hidden"
      };
    },
    bgColor() {
      const color = ["#3e90fe", "#26c393", "#ffad2c", "#f76b6b"];
      const yu = (this.index) % 4;
      return color[yu];
    },
    formatTime() {
      const time = this.mailData.sendTime; // yyyy-mm-dd hh:mm:ss
      const ms = new Date(time).getTime();
      const dd = new Date();
      const firstOfDay = new Date().setHours(0, 0, 0, 0);
      const firstOfYear = new Date(`${dd.getFullYear()}-01-01`).setHours(0, 0, 0, 0);
      // 当天显示 HH：mm
      // 当天之前的显示 MM-DD hh:mm
      // 超过当年的显示 YYYY-MM-DD hh:mm;
      if (ms > firstOfDay) {
        return time.slice(11, 16);
      } if (ms < firstOfDay && ms > firstOfYear) {
        return time.slice(5, 16);
      } if (ms < firstOfYear) {
        return time.slice(0, 16);
      }
      return "";
    },
    statusName() { // 审批状态，1-待审批，2-审批中，3-未通过，4-通过
      const statusMap = {
        1: "待审批",
        2: "审批中",
        3: "未通过",
        4: "通过"
      };
      const { approveStatus, status } = this.mailData;
      if (Number(approveStatus) === 4 && [4, 7].includes(status)) {
        return "发送失败";
      }
      return statusMap[approveStatus];
    },
    statusClass() {
      const statusClassMap = {
        1: "blue",
        2: "yellow",
        3: "red",
        4: "green"
      };
      const { approveStatus, status } = this.mailData;
      if (Number(approveStatus) === 4 && [4, 7].includes(status)) {
        return "red";
      }
      return statusClassMap[approveStatus];
    }
  },
  data() {
    return {
    };
  },
  methods: {
    openMenu(e) {
      if (this.home) {
        return;
      }
      const { mailType } = this.$mailList;
      const { status, id, attachNum } = this.mailData;
      let menuList = [];
      if (status === 1 || status === 3 || status === 5) {
        menuList = [
          {
            val: "回复",
            sendMailKey: 1
          },
          {
            val: "全部回复",
            sendMailKey: 3
          },
          {
            val: "全部回复(带附件)",
            sendMailKey: 4,
            hidden: !attachNum
          },
          {
            val: "转发",
            sendMailKey: 2
          },
          {
            divider: true
          },
          {
            val: "标记已读",
            key: 0
          },
          {
            val: "标记未读",
            key: 1
          },
          {
            divider: true
          },
          {
            val: "移动",
            hidden: mailType === 0,
            children: [
              {
                val: "收件箱",
                moveKey: 1
              },
              {
                val: "已发送",
                moveKey: 2
              },
              {
                val: "已删除",
                moveKey: 4
              },
              {
                val: "垃圾箱",
                moveKey: 5
              }
            ]
          },
          {
            val: status === 1 ? "删除" : "彻底删除",
            key: status === 1 ? 2 : 3
          },
          {
            val: "关联应用",
            key: 4
          }
        ];
      }
      if (status === 2) {
        menuList = [
          {
            val: "编辑",
            sendMailKey: 5
          },
          {
            divider: true
          },
          {
            val: "删除",
            key: 2
          }
        ];
      }
      if (!menuList.length) return;
      const rightInstance = this.$rightmenu.open({
        menuList,
        posobj: {
          left: e.pageX,
          top: e.pageY
        },
        callback: ({ key, sendMailKey, moveKey }) => {
          if (key === 0 || key === 1) {
            this.$mailList.updateMailRead(id, key, true, true);
          }
          if (key === 2 || key === 3) {
            this.$mailList.deleteMail(id, key === 2 ? 1 : 2);
          }
          if (key === 4) {
            // this.$mailList.relatVisible = true;
            // this.$mailList.relatId = id;
            if (this.$mailList.mappingList.length) {
              this.$mailList.mappingIds = id;
              this.$mailList.addMappingflg = true;
              // this.$mailList.relatVisible = true;
            } else {
              this.$message({
                message: "无可用的映射配置，请联系管理员",
                type: "warning"
              });
            }
          }
          if (moveKey) {
            this.$mailList.moveMail(id, moveKey);
          }
          if (sendMailKey) {
            this.$router.push({
              path: "/mailbox/mailSend",
              query: {
                mailId: id,
                mailType: sendMailKey
              }
            });
          }
          rightInstance.close();
        }
      });
    },
    /**
     * @description 点击邮件跳转详情
    */
    handleMailClick() {
      const {
        readFlag, status, id, receiptFlag
      } = this.mailData;
      if (this.home) {
        this.$router.push({
          path: "/mailBox/list",
          query: {
            readFlag, status, id, receiptFlag
          }
        });
        return;
      }
      // 未读邮件以及需要回执 详情以判断
      if (readFlag === 1 && receiptFlag === 0 && status === 1) {
        // this.$mailList.receiptHandler(this.mailData);
      }
      if (readFlag === 1) {
        // 未读邮件需要先变成已读
        this.$mailList.updateMailRead(id, 0, true, true);
      }
      if (status === 2) { // 草稿
        this.$router.push({ path: "/mailBox/mailSend", query: { mailType: "draft", mailId: id } });
        return;
      }
      // 进入详情
      // 获取点击的第几个 zjh
      let mailListIndex = 1;
      this.$mailList.mailList.forEach((element, index) => {
        if (element.id === id) {
          mailListIndex = index + 1;
        }
      });
      this.$mailList.detailId = id;
      this.$mailList.needNext = true;
      this.$mailList.nextObj = {
        pageModel: this.$mailList.pageModel,
        seParams: this.$mailList.seParams,
        url: this.usedFlg === "list" ? "queryMailList" : "searchMail",
        index: mailListIndex
      };
      this.$mailList.detailVisible = true;
    },
    /**
     * @description 提取邮件富文本中的文字
    */
    getSimpleText(html = "") {
      let msg = html;
      // eslint-disable-next-line no-useless-escape
      const re1 = /<script[^>]*?>[\s\S]*?<\/script>/g;
      msg = msg.replace(re1, "");
      const re2 = /<style[^>]*?>[\s\S]*?<\/style>/g;
      msg = msg.replace(re2, "");
      const re3 = /<(.|\s)+?>/g;
      msg = msg.replace(re3, "");
      const re4 = /&lt;|&gt;|\/?p&gt;|&nbsp;/g;
      msg = msg.replace(re4, "");
      return msg;
    }
  }
};
</script>

<style lang='scss' scoped>
.mail-item{
  width: 100%;
  padding: 0 20px;
  &:hover{
    background: #f5f8fc;
    .el-checkbox {
      visibility: visible !important;
    }
  }
}
.mail-item-wrap{
  width: 100%;
  padding: 12px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dce5ec;
  .left{
    width: 15%;
    display: inherit;
    align-items: center;
    .en-icon{
      margin-left: 10px;
    }
    .el-avatar{
      margin: 0 6px;
      position: relative;
      overflow: initial;
      &.noread:after{
        content: " ";
        width: 5px;
        height: 5px;
        background: #f76b6b;
        border-radius: 50%;
        position: absolute;
        z-index: 99;
        right: -5px;
      }
    }

  }
  .user-name{
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #333333;
  }
  .middle{
    width: 60%;
    display: inherit;
    align-items: center;
    .tag-group{
      display: flex;
      justify-content: space-between;
      .en-icon{
        min-width: 30px;
      }
    }
    .content{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 20px;
      .title,.gap{
        font-weight: 600;
        font-size: 14px;
        color: #333333;
      }
      .brief{
        color: #636c78;
        font-size: 12px;
      }
    }
  }
  .right{
    flex: 1;
    text-align: right;
    color: #91a1b7;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .status-mod{
      padding: 5px 10px;
      border-radius: 4px;
      margin-left: 60px;
      &.blue{
        color: #3e90fe;
        background: rgba($color: #3e90fe, $alpha: 0.1);
      }
      &.yellow{
        color: #ffad2c;
        background: rgba($color: #ffad2c, $alpha: 0.1);
      }
      &.red{
        color: #f76b6b;
        background: rgba($color: #f76b6b, $alpha: 0.1);
      }
      &.green{
        color: #26c393;
        background: rgba($color: #26c393, $alpha: 0.1);
      }
    }
  }
}
.home-flex{
  .left{
    width: auto;
    margin-right: 20px;
  }
  .middle{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    .user-name{
      max-width: unset;
      font-size: 12px;
    }
    .content{
      margin:0
    }
  }
}
</style>
