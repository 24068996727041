var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mail-item",
      on: {
        click: _vm.handleMailClick,
        contextmenu: function ($event) {
          $event.preventDefault()
          return _vm.openMenu.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "mail-item-wrap" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            !_vm.home
              ? _c("el-checkbox", {
                  style: _vm.checkboxStyle,
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                  model: {
                    value: _vm.mailData.checked,
                    callback: function ($$v) {
                      _vm.$set(_vm.mailData, "checked", $$v)
                    },
                    expression: "mailData.checked",
                  },
                })
              : _vm._e(),
            _vm.mailData.urgentFlag === 0
              ? _c("en-icon", {
                  attrs: { name: "tishi", color: "#f76b6b", size: "12px" },
                })
              : _vm._e(),
            _c(
              "el-avatar",
              {
                class: { noread: _vm.mailData.readFlag === 1 },
                style: { background: _vm.bgColor },
                attrs: {
                  alt: _vm.mailData.senderName
                    ? _vm.mailData.senderName[0]
                    : "",
                  size: 30,
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.mailData.senderName ? _vm.mailData.senderName[0] : ""
                    ) +
                    " "
                ),
              ]
            ),
            _c("span", { staticClass: "user-name" }, [
              _vm._v(
                _vm._s(_vm.mailData.senderName || _vm.mailData.senderMail || "")
              ),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "middle" }, [
          _c(
            "div",
            { staticClass: "tag-group" },
            [
              _c("en-icon", {
                style: {
                  visibility: _vm.mailData.attachNum > 0 ? "visible" : "hidden",
                },
                attrs: {
                  name: "fujianhetupian-kongjian",
                  size: "16",
                  color: "#B6C0CE",
                  title: "附件",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "content" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.mailData.title)),
            ]),
            _c("span", { staticClass: "gap" }, [_vm._v(" - ")]),
            _c("span", { staticClass: "brief" }, [
              _vm._v(_vm._s(_vm.getSimpleText(_vm.mailData.content))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "right" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.mailData.folderType === 6,
                  expression: "mailData.folderType===6",
                },
              ],
              class: ["status-mod", _vm.statusClass],
            },
            [_vm._v(" " + _vm._s(_vm.statusName) + " ")]
          ),
          _c("span"),
          _c("span", { staticClass: "time" }, [_vm._v(_vm._s(_vm.formatTime))]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }